import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import Img from "gatsby-image";
import SEO from "../components/seo";
import MeetTheHostNav from "../components/meet-the-host-nav";

const MeetTheHostPage = ({ data }) => (
  <Layout>
    <SEO
      title="The Kalb Report | Meet the Host"
      keywords={[`kalb`, `kalb report`, `the kalb report`]}
    />
    <section className="small-page-heading">
      <div className="container">
        <h1>Meet the Host</h1>
      </div>
    </section>
    <section className="section bg-gray about-the-program-section" id="about">
      <div className="container">
        <div className="row">
          <div className="col-lg-3">
            <MeetTheHostNav />
          </div>
          <div className="col-lg-8">
            <Img
              className="bio-picture"
              fluid={data.marvinKalb.childImageSharp.fluid}
              fadeIn={false}
            />
            <p>
              Marvin Kalb is the Edward R. Murrow Professor Emeritus at Harvard
              University’s Kennedy School of Government. He is also a
              nonresident senior fellow with the Brookings Institution. He has
              served as moderator of The Kalb Report public broadcasting series
              since 1994.
            </p>
            <p>
              In a distinguished 30-year broadcast career at CBS News and NBC
              News, he was Chief Diplomatic Correspondent, Moscow Bureau Chief,
              and moderator of Meet the Press. He later served as founding
              director of Harvard’s Shorenstein Center on Media, Politics, and
              Public Policy, and has lectured at many universities, here and
              abroad.
            </p>
            <p>
              Among his many honors are two Peabody Awards, the DuPont Prize
              from Columbia University, the 2006 Fourth Estate Award from the
              National Press Club and more than a half-dozen Overseas Press Club
              awards.
            </p>
            <p>
              A graduate of the City College of New York, Kalb has an M.A. from
              Harvard. He was zeroing in on his Ph.D. in Russian history when he
              left Cambridge in 1956 for a Moscow assignment with the State
              Department. The following year, he joined CBS News, becoming the
              last correspondent personally hired at the network by Edward R.
              Murrow.
            </p>
            <p>
              Kalb has authored or co-authored 17 nonfiction books and two
              best-selling novels, including “The Year I was Peter the Great”
              (2017) and &quot;Enemy of the People: Trump&#39;s War on the
              Press, the New McCarthyism, the Threat to American Democracy&quot;
              (2018), and Assignment Russia: Becoming a Foreign Correspondent in
              the Crucible of the Cold War (2021).
            </p>
          </div>
          <div className="col-lg-3 offset-lg-1" />
        </div>
      </div>
    </section>
  </Layout>
);

export const query = graphql`
  query {
    marvinKalb: file(relativePath: { eq: "marvin_kalb.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default MeetTheHostPage;
